import "cookieconsent";
import * as M from "materialize-css/dist/js/materialize";

export class Layout {

    constructor() {
        this.initialiseCookieConsent();
        this.initialiseControls();
    }

    public initialiseCookieConsent(): void {
        document.addEventListener("DOMContentLoaded", () => {
            (window as any).cookieconsent.initialise({
                palette: {
                    button: {
                        background: "#4e342e",
                        text: "#ffffff",
                    },
                    popup: {
                        background: "#757575",
                    },
                },
                position: "bottom",
                theme: "mono",
            });
        });
    }

    public initialiseControls(): void {
        const sideNavElements = document.querySelectorAll(".sidenav");
        M.Sidenav.init(sideNavElements);

        const dropDownTriggerElements = document.querySelectorAll(".dropdown-trigger");
        M.Dropdown.init(dropDownTriggerElements, { constrainWidth: false });
    }
}
